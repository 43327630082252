.item-with-border {
  border: 0.5px solid #ECEBEB;;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  gap: 5px !important;
}

.parent-item {
  gap: 10px !important;
}

.train-icon {
  display: flex;
  margin-top: 23px;
}

.cardStyle {
  box-shadow: none !important;
  width: 100% !important;
}

.icon {
  font-size: small !important;
  color: grey;
}

.vehicle {
  padding-right: 20px;
}

@media only screen and (min-width: 700px) {
  .icon {
    font-size: small !important;
    color: grey;
    margin-left: 2;
  }
}
