.loader {
    justify-content: center;
}

.loader-heading {
    margin-top: 48px;
    text-align: center;
}

.loader-image {
    margin-top: 33px;
    width: 174px;
    height: 80px;
}

@media only screen and (min-width: 700px) {
    .loader-image {
        margin-left: 45%;
  }
}

