.panel-styledbox {
    position: absolute;
    padding-bottom: 2;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    visibility: visible;
    right: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: clip;
}

.panel-container {
    height: '100%'
}